/* .app{
  background-color: #2F2D2E;
} */

/* #root > div > div.MuiBox-root.css-zf0iqh

{
  background-color: #222;
} */

@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@1,600&display=swap');

.glow {
  font-size: 30px;
  text-align: center;
  color: #f3f3f3;
  animation: glow 1s ease-in-out infinite alternate;
}

.slowglow {
  font-size: 30px;
  text-align: center;
  color: #000000;
  animation: glow 10s ease-in-out infinite alternate;
}

.noglow {
  font-size: 23px;
  text-align: center;
  color: #fff;

}

#spotify-profile {
  width: 30%;
  height: 40%;
  border-radius: 20px;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100 vh
}

.content-wrap {
  flex: 1;
}

@keyframes glow {
  from {
    text-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 15px #0073e6, 0 0 20px #0073e6, 0 0 25px #0073e6, 0 0 30px #0073e6, 0 0 35px #0073e6;
  }
  to {
    text-shadow: 0 0 3px #fff, 0 0 25px #fff, 0 0 30px #0073e6, 0 0 40px #0073e6, 0 0 50px #0073e6, 0 0 60px #0073e6, 0 0 70px #0073e6;
  }
}

iframe {
border: 0; width: 350px;
}

h1 {

  /* text-shadow: 0px 10px 10px #000000; */
  text-shadow: 0px 20px 20px #e93663, 0px 0px 20px #fff;
  font-family: "Expletus Sans" !important;
  font-weight: 900;
  font-style: italic;

 

}

a {
  color: #FF8886;
}
.fa-xl {
  padding: 16px;
}

.fa-spotify:hover, .fa-spotify:focus {
  color: #1db954;
}

.fa-apple:hover, .fa-apple:focus {
  color: #555555;
}

.fa-instagram:hover, .fa-apple:focus {
  color: #F5f5f5;
}


.fa-tiktok:hover, .fa-apple:focus {
  color: #00f2ea;
  
}

input {
  margin: 10px;
  padding: 10px;
}

textarea {
  width: 340px;
}

h5 {
  font-weight: 900;
}

@media screen and (max-width: 460px) {

  
   h1 {

    /* text-shadow: 0px 10px 10px #000000; */
   
  padding-left: 20px;
  font-size: 60px !important;
  
  
  }
.single {
  padding-left: 10px;;
}

}